<template>
<div>
  <nut-navbar
    :left-show="false"
    @on-click-icon="titIconClick"
    :title="$t('bankCard.h1')"
    titIcon="home"
    fixed=true
    class="mb0 nav-icon-right nut-navbar--fixed">
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
  </nut-navbar>

  <div class="login add-card">
    
    <div class="login-bn"></div>
    <div class="login-bd">
      <h3 class="s2">{{ edit ? editTitle : addTitle }}</h3>
      <p class="addcard-text">{{$t('bankCard.addcard_text')}}</p>


      <div class="login-inp">
        <label class="fs12 fc-999">Select Account Type<span class="c-red">*</span></label>
        <p class="err-info" v-show="typeErrText">{{typeErrText}}</p>
        <nut-radiogroup v-model="bankInfo.type" direction="horizontal" @change="typeChange()">
          <nut-radio shape="button" label="gcash">GCash Account</nut-radio>
          <nut-radio shape="button" label="maya">Maya Account</nut-radio>
        </nut-radiogroup>
      </div>

      <div class="login-inp" v-show="bankInfo.type ==='gcash'">
        <label class="fs12 fc-999">GCash<span class="c-red">*</span></label>
        <p class="err-info" v-show="accountErrText">{{accountErrText}}</p>
        <login-input
          label=""
          placeholder="GCash Account"
          type="text"
          rule="^.{4,100}$"
          maxLength="100"
          v-model="bankInfo.accountNum"
          @inputChange="(res) => (bankInfo.accountNum = res)"
        />
      </div>

      <div class="login-inp" v-show="bankInfo.type ==='maya'">
        <label class="fs12 fc-999">Maya<span class="c-red">*</span></label>
        <p class="err-info" v-show="accountErrText">{{accountErrText}}</p>
        <login-input
          label=""
          placeholder="Maya Account"
          type="text"
          rule="^.{4,100}$"
          maxLength="100"
          v-model="bankInfo.accountNum"
          @inputChange="(res) => (bankInfo.accountNum = res)"
        />
      </div>

      <div class="login-inp" v-show="bankInfo.type ==='gcash' || bankInfo.type ==='maya'">
        <label class="fs12 fc-999">Name<span class="c-red">*</span></label>
        <p class="err-info" v-show="nameErrText">{{nameErrText}}</p>
        <login-input
          label=""
          placeholder="Your Name"
          type="text"
          rule="^.{1,100}$"
          maxLength="100"
          v-model="bankInfo.name"
          @inputChange="(res) => (bankInfo.name = res)"
        />
      </div>


      
      
      <div class="login-btn">
        <nut-button block type="info" @click="addBankCard"
          >{{$t('global.confirm')}}</nut-button
        >
      </div>
      
    </div>
  </div>

</div>


</template>

<script>
import { reactive } from "vue";
import { useRouter } from "vue-router";
import loginInput from "@/components/loginInput.vue";
import axios from "axios";
import { Toast } from "@nutui/nutui";

export default {
  components: {
    loginInput,
  },
  data() {
    return {
      editTitle: this.$t('bankCard.editTitle'),
      addTitle: this.$t('bankCard.addTitle'),
      edit:false,
      bankNamePopup: false,
      id: null,
      target: null,
      bankInfo: {
        type: "gcash",
        accountNum: "",
        name:"",
      },
      typeErrText: '',
      accountErrText: '',
      nameErrText: '',
    };
  },

  methods: {
    typeChange(){
      this.bankInfo.accountNum = ""
    },
    gettingData() {
      if(this.$route.query.id){
        this.edit = true
        this.id = this.$route.query.id
        this.bankInfo.type = this.$route.query.type
        this.bankInfo.accountNum = this.$route.query.accountNum
        this.bankInfo.name = this.$route.query.name
      }
      if(this.$route.query.target){
        this.target  = this.$route.query.target
      }
    },
    addBankCard() {
      this.typeErrText = ''
      this.accountErrText = ''
      this.nameErrText = ''
      if(this.bankInfo.type === ""){
        this.typeErrText = this.$t('global.errInfoRequired')
        return
      }else if(this.bankInfo.accountNum === ""){
        this.accountErrText = this.$t('global.errInfoRequired')
        return
      }else if(this.bankInfo.name === ""){
        this.nameErrText = this.$t('global.errInfoRequired')
        return
      }else {
        axios.post("/api/user/bank/save", {
            id: this.id,
            bankInfo: this.bankInfo
          })
          .then((response) => {
            if (response.data.code === 0) {
              Toast.success(this.$t('bankCard.success'));
              setTimeout(() => {
                if(this.target){
                  this.$router.push({
                    path: this.target,
                    query: {
                      amountType: 'coin'
                    }
                  });
                }else{
                  this.$router.push("/bankCard");
                }
              }, 1000);
            } else if (response.data.code === 1004){
              let res = response.data.data
              Toast.fail(this.$t('global.INVALID_INPUT_VALUE'));
              for(var i in res){	
                switch (res[i].fieldName) {
                  case "type":
                    this.typeErrText = res[i].description
                    break;
                  case "accountNum":
                    this.accountErrText = res[i].description
                    break;
                  case "name":
                    this.nameErrText = res[i].description
                    break;
                }
              }
            }
          })
      }
    }
  },
  watch: {
    '$route': 'gettingData'
  },
  created() {
    this.gettingData()
  },
  setup() {
    const state = reactive({});
    const router = useRouter();
    const methods = {
      backClick() {
        router.go(-1);
      },
      titIconClick(){
        router.push({
        name: "myHome",
      });
      }
    };
    return {
      ...methods,
      state,
    };
  },
};
</script>

<style scoped></style>
<style>
.add-card .nut-radiogroup--horizontal .nut-radio{ margin-right: 7px;}
.add-card .nut-radio__button{ border: 1px solid #fff;padding: 8px 20px;border-radius: 25px; font-size: 14px; width: 100%;}
.add-card .nut-radio__button--active{ background: #000; color: #ffe114; border: 1px solid #ffe114;}
</style>