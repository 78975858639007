<template>
  <div class="index gameLobby" >
    <div class="inx-top">
      <div class="c">
          <div class="l">
            <span class="icon-b" v-show="downloadAppShow">
              <a :href="appDownloadUrl">
                <span @click="downloadAppClose">Download APP</span>
              </a>
            </span>
          </div>
          <div class="r">
            <div class="inx-tg-btn">
              <img src="../assets/images/global/bg207.png" @click="toTelegram"/>
            </div>
          </div>
        </div>
    </div>

    <div class="index-main" >

      <div class="inx-bn">
        <nut-swiper :init-page="0" :pagination-visible="true" pagination-color="#00EDA6" pagination-unselected-color="#ffffff" auto-play="3000" height="auto" :is-prevent-default="false" >

          <nut-swiper-item >
            <router-link to="/agentPromotion">
              <img src="../assets/images/bn3.jpg"/>
            </router-link>
          </nut-swiper-item>

          <nut-swiper-item v-if="!show_w3">
            <router-link to="/agentPromotion">
              <img src="../assets/images/bn3c.jpg"/>
            </router-link>
          </nut-swiper-item>
          <nut-swiper-item v-if="show_w3">
            <router-link to="/agentPromotion">
              <img src="../assets/images/bn3b.jpg" />
            </router-link>
          </nut-swiper-item>


          <nut-swiper-item>
            <router-link to="/rechargeBalance">
              <img src="../assets/images/bn9.jpg"/>
            </router-link>
          </nut-swiper-item>

          <nut-swiper-item>
              <router-link to="/businessPartners"><img src="../assets/images/bn11.jpg"/></router-link>
          </nut-swiper-item>
          
          

          <nut-swiper-item>
            <router-link  :to="{ path: 'liveGame', query: { id: 'CQ9_cq9_GINKGO01', platform:'cq9', name:'motivation'}}">
              <img src="../assets/images/bn10.jpg"/>
            </router-link>
          </nut-swiper-item>

          <nut-swiper-item>
              <img src="../assets/images/bn1.jpg"/>
          </nut-swiper-item>
 
          <nut-swiper-item>
            <router-link to="/agentrgGrowth"><img src="../assets/images/bn2.jpg"/></router-link>
          </nut-swiper-item>


        </nut-swiper>
      </div>

      <div class="inx-hd" v-if="!showLogin || userInfo.avatar" >
        <div class="avatar-l">
          <img :src="userInfo.avatar"/>
        </div>

        <div class="btn-r" >

          <div class="s1">
            <span>{{$t('withdrawal.gameAccount')}}</span>
            <strong>
              {{ $filters.currencySymbol(userInfo.availableAmount) }}
            </strong>
          </div>

          <div class="s3 avatar-r">
            <div>
              <nut-button size="small" plain type="info" class="inx-btn"  @click="rechargeClick">
                {{$t('home.recharge')}}
              </nut-button>
              <nut-button size="small" type="info"  class="inx-btn inx-btn-s2" style="margin-top: 5px;" @click="withdrawalClick('coin')">
                {{$t('home.withdrawal')}}
              </nut-button>
            </div>
          </div>

          <div class="s2" v-if="userInfo.vipLevel === 0"  @click="infoToast('Chips are given as gifts from the platform. You can use them to experience original games. If you deposit up to ₱1000, the chip balance will be converted into game account coins and can be withdrawn.')">
            <span>{{$t('withdrawal.chipAccount')}}</span>
            <strong>
              {{ $filters.currencySymbol(userInfo.chipAmount) }}
            </strong>
            <div class="tx">Originals Only <i class="icon-new icon-i1" style="width: 15px; height: 15px;"></i></div>
          </div>

        </div>

      </div>

      <div class="inx-fd lobby-tab">

        <h4>
          <strong :class="lobbyTabType ===  'hot' ? 'a': ''" @click="lobbyTabClick('hot')">Hot Game</strong>
          <strong :class="lobbyTabType ===  'new' ? 'a': ''" @click="lobbyTabClick('new')">New Game</strong>
          <strong :class="lobbyTabType ===  'recent' ? 'a': ''" @click="lobbyTabClick('recent')">Recent Play</strong>
        </h4>

        <div class="link-8x-box">

          <div class="link-3x link-8x" v-if="lobbyTabType ===  'hot'">
            <div v-for="(item, index) in promoteGameList" :key="index"  class="link-i" @click="toGameHome(item.gameId, item.platform, item.name)">
              <img  @error="errorImg"  :src="getImgUrl(item.platform, item.gameId)" />
            </div>
          </div>

          <div class="link-3x link-8x" v-else-if="lobbyTabType ===  'new'">
            <div v-for="(item, index) in newGameList" :key="index"  class="link-i" @click="toGameHome(item.gameId, item.platform, item.name)">
              <img  @error="errorImg"  :src="getImgUrl(item.platform, item.gameId)" />
            </div>
          </div>

          <div class="link-3x link-last-game" v-else-if="lobbyTabType ===  'recent'">
              <div v-for="(item, index) in lastGameList" :key="index"  class="link-i" @click="toGameHome(item.gameId, item.platform, item.name)"  v-show="index < 4">
                <img  @error="errorImg"  :src="getImgUrl(item.platform, item.gameId)" />
              </div>
          </div>
        </div>
      </div>

      <div class="lobby-tab">
        <nut-tabs style="height:480px" v-model="tabValue" title-scroll direction="vertical"  @click="gameTabsClick">
          
          <nut-tabpane  title="Slots">
            <div class="link-2x link-2x-jili">
              <div v-for="(item, index) in jiliGamesSlots" :key="index"  class="link-i" @click="toGameHome(item.gameId, item.platform, item.name)" v-show="index < 12">
                <img @error="errorImg"  :src="getImgUrl(item.platform, item.gameId)" />
              </div>
            </div>
            <div class="view-all"  @click="toGamesList('slots')"><span>View All Slots</span></div>
          </nut-tabpane>

          <nut-tabpane  title="Originals" >
            <div class="link-2x">
              <div  v-for="(item, index) in lobbyGames" :key="index"  class="link-i" @click="toGameHome(item.gameId, item.platform, item.name)">
                <img v-if="item.gameId === 'crash2'" :src="require('../assets/images/global/gm1.png')" />
                <img v-if="item.gameId === 'parity'" :src="require('../assets/images/global/gm3.png')" />
                <img v-if="item.gameId === 'mine'" :src="require('../assets/images/global/gm2.png')" />
                <img v-if="item.gameId === 'wheel'" :src="require('../assets/images/global/gm4.png')" />
                <img v-if="item.gameId === 'andar'" :src="require('../assets/images/global/gm5.png')"/>
                <img v-if="item.gameId === 'dice'"  :src="require('../assets/images/global/gm7.png')"/>
              </div>
            </div>
          </nut-tabpane>

          <nut-tabpane  title="Live" >
            <div class="link-2x link-2x-jili">
              <div>
                <div class="live-game-btn" style="margin-bottom: 10px;" @click="toLiveGame('CQ9_cq9_GINKGO01', 'cq9', 'motivation')">
                  <img src="../assets/images/lobby_icon/live-bn.jpg" />
                </div>
              </div>
            </div>
          </nut-tabpane>
          
          <nut-tabpane  title="Poker">
            <div class="link-2x link-2x-jili">
              <div v-for="(item, index) in jiliGamesPoker" :key="index"  class="link-i" @click="toGameHome(item.gameId, item.platform, item.name)"  v-show="index < 12">
                <img @error="errorImg"  :src="getImgUrl(item.platform, item.gameId)" />
                <p class="name">{{item.name}}</p>
              </div>
            </div>
            <div class="view-all"  @click="toGamesList('poker')"><span>View All Poker</span></div>
          </nut-tabpane>

          <nut-tabpane  title="Casino" >
            <div class="link-2x link-2x-jili">
              <div v-for="(item, index) in jiliGamesCasino" :key="index"  class="link-i" @click="toGameHome(item.gameId, item.platform, item.name)"  v-show="index < 12">
                <img @error="errorImg"  :src="getImgUrl(item.platform, item.gameId)" />
                <p class="name">{{item.name}}</p>
              </div>
            </div>
            <div class="view-all"  @click="toGamesList('casino')"><span>View All Casino</span></div>
          </nut-tabpane>

          <nut-tabpane  title="Fishing" >
            <div class="link-2x link-2x-jili">
              <div v-for="(item, index) in jiliGamesFish" :key="index"  class="link-i" @click="toGameHome(item.gameId, item.platform, item.name)"  v-show="index < 12">
                <img @error="errorImg"  :src="getImgUrl(item.platform, item.gameId)" />
                <p class="name">{{item.name}}</p>
              </div>
            </div>
            <div class="view-all"  @click="toGamesList('fishing')"><span>View All Fishing</span></div>
          </nut-tabpane>
          
        </nut-tabs>
      </div>

      <div class="view-all"  @click="toGamesList('all')">
        <span>View All</span>
      </div>

      <div class="pinpai-logo">
        <img src="../assets/images/lobby_icon/pplogo.png" />
      </div>


      <div class="live-game-btn" @click="toLiveGame('CQ9_cq9_GINKGO01', 'cq9', 'motivation')">
        <img src="../assets/images/lobby_icon/live-bn.jpg" />
      </div>

      <div class="index-nav" style="margin-top: 10px;">
        <div class="s1"><strong>Secure</strong><span>Secure & Real</span></div>
        <div class="s2"><strong>Easy</strong><span>Quick deposits</span></div>
        <div class="s3"><strong>Fast</strong><span>60s withdraw success</span></div>
      </div>

      <div class="inx-fd">
        <h4><strong class="icon-top">{{$t('global.top10')}}</strong></h4>
        <div class="top-10">
          <ul>
            <li v-for="(item, index) in winRank" :key="index">
              <i>{{ index+1 }}</i>
              <img :src="require('../assets/images/avatar/' + item.avatar.slice(item.avatar.length-6))"/>
              <span>{{ item.desensitizedMobile }}</span>
              <strong>{{ $filters.currencySymbol(item.winAmount) }}</strong>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!--**************************************** tg 提醒 ****************************************-->
    <nut-popup pop-class="pop-vip-lobby" v-model:visible="vipTgDialog" :z-index="100">
      <VipRules :myLevel="0" :popTitle="popTitle"></VipRules>
      <div class="bd">
        {{ $t('global.Prediction_Channel_Text') }}<br/>
      </div>
      <div class="btn" style="margin: 0;">
        <nut-button style="width: 48%;margin: 0 1%;" type="primary" @click="vipTgDialog = false">{{ $t('global.Got_It') }}</nut-button>
        <nut-button style="width: 48%;margin: 0 1%;" type="primary" @click="rechargeBtnClick">{{ $t('global.Upgrade') }}</nut-button>
      </div>
    </nut-popup>

  </div>
  <tabbar :activeIndex="tabbarActiveIndex"></tabbar>

  <nut-drag direction="y" :style="{ bottom: '60px', right:'5px', zIndex: '1000 !important'}"  @click="toHelp" >
    <div class="drag-wb-history">
      <img :src="require('../assets/images/global_icon/202405-66.png')"/>
    </div>
  </nut-drag>
  <!-- <div class="to-foot" @click="toFoot()"></div> -->

  <div class="reg-store-coin"  v-show="regCoing"> 
    <div class="bd">
        <h3><span>Welcome to OKADA CLUB!</span></h3>
        <p>We have prepared generous bonuses for you. If you complete any <strong>FIRST DEPOSIT</strong>, you can get max <strong>50%</strong> first deposit bonus!</p>

        <h4>For example</h4>
        <div class="example">
          <p>First deposit ₱200, receive <strong>₱300</strong>! Get ₱200 extra bonus!(50% first deposit bonus)</p>
          <p>Deposit reach ₱1000, receive <strong>₱1350</strong>! Get ₱350 extra bonus!--₱250 (25% first deposit bonus) + ₱100 (exclusive  bonus) </p>
        </div>
        <div class="btn">
          <nut-button type="primary" @click="hideRegStoreCoin">Close</nut-button>
        </div>
    </div>
  </div>


  <!-- 转盘开关 -->
  <nut-drag direction="y" :style="{ bottom: '130px', right:'5px', zIndex: '1000 !important'}"  @click="spinShow()" v-if="spinActivityStatus" >
    <div class="drag-wb-history button-am">
      <img :src="require('../assets/images/global_icon/202405-67.png')"/>
    </div>
  </nut-drag>

  <!-- 转盘弹窗 -->
  <nut-popup pop-class="pop-lucky-roulette"  v-model:visible="spinPopup" >
    <div class="l-main">
      <div class="l-top">
        <div class="close-btn" @click="spinPopup = false">&nbsp;</div>
        <div class="l-title"></div>
        <div class="l-time">
          <span>End time: {{ spinInfo.endTime }}</span>
          <div class="t">
            <nut-countdown :endTime="countDown" millisecond format="DD HH:mm:ss"
                style="display: inline-block; font-weight: 600; color: #ffe269; font-size: 20px;"/>
          </div>
        </div>
        
        <div class="l-cs l-cs2" v-if="(!userToken && !userSpinTpye) || ( userToken && !userSpinTpye)">
          <p style="font-size: 14px;">You have free spin chance, try your luck now!</p>
        </div>
        <div class="l-cs" v-else-if="!userToken" >
          <p>Remaining spin chance <strong>0</strong></p>
        </div>
        <div class="l-cs" v-else>
          <p>Remaining spin chance <strong>{{ spinInfo.availableSpin }}</strong></p>
          <!-- <p>Remaining spin chance <strong><nut-countup :scrolling="true" :init-num='0' :end-num='spinInfo.availableSpin' :during="100"></nut-countup></strong></p> -->
          
        </div>


        
      </div>
      <div class="l-body" v-if="spinShowPopup">
        <div class="spin-box">
          <nutbig-turntable
            class="turntable"
            ref="turntable"
            width="280px"
            height="280px"
            :lock-time="lockTime"
            :prize-list="prizeList"
            :turns-number="turnsNumber"
            :turns-time="turnsTime"
            :prize-index="prizeIndex"
            :style-opt="styleOpt"
            @start-turns="startTurns"
            @end-turns="endTurns"
          ></nutbig-turntable>
        </div>
      </div>
      
      <div class="l-fd" v-if="userToken && userSpinTpye && spinInfo.participationStatus !== 'received' ">
        <nut-button class="l-btn" block type="info" @click="showReceiveReward">
          Receive reward 
          <!-- <strong><nut-countup :scrolling="true" :init-num='0' :end-num='spinInfo.rewardAmount' :num-width="14" :speed="2" :during="1"></nut-countup></strong> -->
          <strong>{{ spinInfo.rewardAmount }}</strong>
          <span>/{{rewardAmountMax}}</span>
        </nut-button>
      </div>

      <div class="l-progress"  v-if="userToken && userSpinTpye  && spinInfo.participationStatus !== 'received'">
        <nut-progress :percentage="percentage" stroke-color="linear-gradient(270deg, #ffe114 0%,#e49600 33%,#cd6f00 100%)" status="active" stroke-width="15"  :show-text="true"   size="large" />
      </div>
      
      <div class="end-turns-main" v-if="showEndTurnsBox">  
        <div class="end-turns-box">
          <p>You have won</p>
          <strong>{{ $filters.currencySymbol(reward) }}</strong>
          <div class="btn">
            <nut-button type="primary" @click="receiveTurnsReward">Receive Reward</nut-button>
          </div>
        </div>
      </div>

      <div class="end-turns-main2" v-if="showFirstTurnsBox">  
        <div class="end-turns-box">
          <p class="title"><span>Congratulations!</span><strong>You have won</strong></p>
          <strong class="strong">{{ $filters.currencySymbol(spinInfo.initialRewardAmount) }}</strong>
          <div class="p">
            <p>Please register account before the end of the event, and the {{ $filters.currencySymbol(spinInfo.initialRewardAmount) }} reward will be automatically credited to your account and can be withdrawn.</p>
          </div>
          <div class="btn">
            <nut-button type="primary" @click="toRegister">Register</nut-button>
          </div>
        </div>
      </div>

      <div class="end-turns-main3" v-if="showFirstTurnsBox2">  
        <div class="end-turns-box">
          <p>Congratulations</p>
          <strong>x{{ spinInfo.availableSpin }}</strong>
          <p class="p">you have completed the registration and got {{ spinInfo.initialChance }} new chances to spin the wheel!</p>
          <div class="btn">
            <nut-button type="primary" @click="closeFirstTurnsBox">Go!</nut-button>
          </div>
        </div>
      </div>

      <div class="end-turns-main2" v-if="showOldUserFirstTurnsBox">  
        <div class="end-turns-box">
          <p class="title"><span>Congratulations!</span><strong>You have won</strong></p>
          <strong class="strong">{{ $filters.currencySymbol(spinInfo.initialRewardAmount) }}</strong>
          <div class="p">
            <p>Remaining free spin chances {{ spinInfo.initialChance }}, keep trying your luck!</p>
            <div class="d">When the reward accumulates to ₱1000, it will be automatically added to your agent account and can be withdrawn.</div>
          </div>
          <div class="btn">
            <nut-button type="primary" @click="closeFirstTurnsBox">Go!</nut-button>
          </div>
        </div>
      </div>

      <div class="end-turns-main2 success-box" v-if="rewardsReceivedSuccessBox">  
        <div class="end-turns-box">
          <p class="title"  style="line-height: 40px; font-size: 18px;"><span>Congratulations!</span><strong style=" font-size: 22px;">You have received</strong></p>
          <strong class="strong" style="margin-top: 18px;">{{ $filters.currencySymbol(spinInfo.rewardAmount) }}</strong>
          <div class="p">
            <p>Reward will be automatically credited to your Agent account and can be withdrawn.You can check it on the invite page.</p>
          </div>
          <div class="btn">
            <nut-button type="primary" @click="rewardsReceivedSuccess">OK</nut-button>
          </div>
        </div>
      </div>


      
      <div class="task-list" v-if="showTaskList">

        <div class="l-cs">
          <p>Remaining spin chance <strong>{{ spinInfo.availableSpin }}</strong></p>
        </div>
        <div class="d1">
          <div class="tx"><strong>{{ spinInfo.rewardAmount }}</strong><span>/{{rewardAmountMax}}</span></div>
        </div>

        <nut-progress :percentage="percentage" stroke-color="linear-gradient(270deg, #ffe114 0%,#e49600 33%,#cd6f00 100%)" status="active" stroke-width="15"  :show-text="true"   size="large" />
        
        <div class="l-tx">Complete any of the following tasks to get a new spin chance</div>
        <div class="l-btn-box">
          <div class="b-btn">
            <div class="bg1">
              <p>Invite {{spinInfo.inviteChanceConf[spinInfo.inviteStage]}} friends to register<br/><span>{{spinInfo.inviteCount}}/{{spinInfo.inviteChanceConf[spinInfo.inviteStage]}}</span></p>
              <nut-button class="l-btn" block type="info" @click="toInvite">Invite</nut-button>
              <!-- <div :class="'ico ico-t' + spinInfo.inviteStage "></div> -->
            </div>
          </div>
          <div class="b-btn">
            <div class="bg2">
              <p>Deposit {{$filters.currencySymbol(spinInfo.rechargeChanceConf[spinInfo.rechargeStage])}}<br/><span>{{spinInfo.rechargeAmount}}/{{spinInfo.rechargeChanceConf[spinInfo.rechargeStage]}}</span></p>
              <nut-button class="l-btn" block type="info" @click="toDeposit">Deposit</nut-button>
              <!-- <div :class="'ico ico-t' + spinInfo.rechargeStage "></div> -->
            </div>
          </div>
          <div class="b-btn">
            <div class="bg3">
              <p>Bet {{$filters.currencySymbol(spinInfo.betChanceConf[spinInfo.betStage])}}<br/><span>{{spinInfo.betAmount}}/{{spinInfo.betChanceConf[spinInfo.betStage]}}</span></p>
              <nut-button class="l-btn" block type="info"  @click="toPlay">To play</nut-button>
              <!-- <div :class="'ico ico-t' + spinInfo.betStage "></div> -->
            </div>
          </div>

          <div class="close-btn" @click="showTaskList = false">&nbsp;</div>
        </div>
        
      </div>

      

    </div>
  </nut-popup>

    <nut-popup position="bottom" closeable round :style="{ height: '22%' }" v-model:visible="jiliLink">
      <div class="jili-btn-box">
        <span class="btn btn-s1" @click="jiLiplayGame">PLAY NOW</span>
        <span class="btn btn-s2" @click="jiLiDemoGame">FREE TRIAL</span>
      </div>
    </nut-popup>


</template>

<script>
import {ref, reactive, toRefs, onMounted, getCurrentInstance} from "vue";
import {useRouter} from "vue-router";
import TabbarMain from "../components/TabbarMain.vue";
import { Toast, Dialog } from "@nutui/nutui";
import axios from "axios";
import {isBlank,isNotBlank} from "@/assets/js/commonUtil";
import { getCountry, searchPlatform} from "@/assets/js/environment";
import VipRules from "../components/VipRules.vue";
import { getClientType } from "@/assets/js/commonUtil";


import "@nutui/nutui-bingo/dist/style.css";

export default {
  name: "gamesLobby",
  components: {
    tabbar: TabbarMain,
    VipRules: VipRules,
  },
  data() {
    return {
      country: '',
      commonInfo: '',
      downloadTitle:'',
      downloadAppShow: true,
      menuDownloadAppShow: '',
      appDownloadUrl:'',
      addDesktopShow: '',
      target: 300,
      show_w3:false,
      popTitle: this.$t('recharge.popTitle_1'),
      i18n:{
        loading: this.$t('global.loading'),
      },
      clientType: null,
      errorImg: 'this.src="' + require('../assets/images/global/error-img.png') + '"',
    };
  },
  created() {
    this.country = getCountry()
    // this.commonInfo = JSON.parse(localStorage.getItem("commonInfo"))
    axios({
      url: "/api/system/common",
      method: "get",
    }).then((res) => {
      localStorage.setItem("commonInfo",JSON.stringify(res.data.data));
      this.commonInfo = res.data.data
    });

    if(localStorage.getItem("downloadApp") === 'close'){
      this.downloadAppShow = false
      this.addDesktopShow = false
    }else{
      this.showDownloadTip();
    }
    let d = new Date()
    // 1100活动 星期3
    this.show_w3 = (d.getDay() === 3)

  },
  mounted() {
    
  },

  methods: {
    infoToast(msg){
      Dialog({
        content: msg,
        okText: this.$t('global.okText'),
        noCancelBtn: true
      });
    },
    getImgUrl(platform,gameId){
        try {
          if(platform === 'cq9'){
            return require('@/assets/images/lobby_icon/'+ platform + '/280_280_EN/280x280_EN_GAMEID_' + gameId + '.png')
          }else if(platform === 'jili'){
            return require('@/assets/images/lobby_icon/'+ platform + '/260_380_EN/260x380_EN_GAMEID_' + gameId + '.png')
          }else{
            // lobby yw类型的时候 返回jili目录下的图标
            return require('@/assets/images/lobby_icon/jili/260_380_EN/260x380_EN_GAMEID_' + gameId + '.png')
          }
        } catch (error) {
          return require('../assets/images/global/error-img.png')
        }
    },
    //下载app
    downloadApp(){
        window.open(this.appDownloadUrl);
    },
    downloadAppClose(){
      // this.addDesktopShow = false;
      // this.downloadAppShow = false;
      // localStorage.setItem("downloadApp", 'close');
    },
    showDownloadTip() {
      this.clientType = getClientType();
      if (this.clientType == "ios") {
        this.addDesktopShow = true;
        this.downloadAppShow = false;
        this.downloadTitle = this.$t('global.addDesktop');
      } else if (this.clientType == "android") {
        this.addDesktopShow = false;
        this.downloadAppShow = true;
        this.menuDownloadAppShow = true;
        if (window.localStorage.getItem("commonInfo")) {
          let commonInfo = JSON.parse(window.localStorage.getItem("commonInfo"));
          this.appDownloadUrl = commonInfo.appDownloadUrl;
        }
        if(this.appDownloadUrl === '-') {
          this.downloadAppShow = false;
          return
        }
        this.downloadTitle = this.$t('global.downloadApp');
      }
    }
  },
  setup() {
    const _data = getCurrentInstance();
    const turntable = ref(null);
    //const prizeList = ref([]);
    const state = reactive({
      regCoing: false,
      regStoreCoin: 0,
      regStoreCoinOn: false,
      tabValue: '0',
      list5: [1,2,3],
      lobbyGames: [],
      jiliGamesSlots: [],
      jiliGamesPoker: [],
      jiliGamesOther: [],
      jiliGamesFish: [],
      jiliGamesCasino: [],
      jiliGamesSlotsData: [],
      jiliGamesPokerData: [],
      jiliGamesOtherData: [],
      jiliGamesFishData: [],
      jiliGamesCasinoData: [],
      promoteGameList: [],
      newGameList: [],
      tabbarActiveIndex: 0,
      showLogin: true,
      loadingCompleted: false,
      userInfo: {
        mobile: "",
        inviteCode: "",
        avatar: "",
        vipLevel: "",
        hasPassword: "",
        vipTitle: "",
        vipBg: "",
        availableAmount: 0,
        chipAmount:0
      },
      winRank: [],
      vipTgDialog: false,
      myLastTimeGameList: [],
      lastGameList: [],
      lobbyTabType: 'hot',
      jiliLink:false,
      jiliGameUrl:'',
      jiliGameQuery:{
        id:'',
        name:'',
        platform:''
      },
      userToken: isNotBlank(localStorage.getItem("token")),
      userSpinTpye: null,  //firsting, ongoing
      localStorageSeqNum: '',
      countDown:0,
      spinActivityStatus: false,
      spinPopup: true,
      spinShowPopup:true,
      showEndTurnsBox: false,
      showFirstTurnsBox: false,
      showFirstTurnsBox2: false,
      showOldUserFirstTurnsBox: false,
      rewardsReceivedSuccessBox: false,
      showTaskList: false,
      rewardAmountMax:1000,
      percentage:0, //进度
      spinInfo:{
        seqNum: 0,
        activityStatus: '',  // enable disable
        startTime: "", 
        endTime: "", 
        availableSpin: 0,
        rewardAmount: 0,
        participationStatus: "",  //1 ongoing / wait_receive / received
        betChanceConf: "",
        betStage: 0,
        betAmount: 0,
        inviteChanceConf: "",
        inviteStage: 0,
        inviteCount: 0,
        rechargeChanceConf: "",
        rechargeStage: 0,
        rechargeAmount: 0,
        initialChance: 0,
        initialRewardAmount: 0,
      },
      temp:{
        participationStatus: "",
        rewardAmount:0,
        percentage:0,
      },
      spinUserFirst: true,
      turnsNumber: 15, //圈数
      turnsTime: 5, // 时间(秒)
      lockTime: 3,
      prizeIndex: null, //中奖的奖品index
      prizeList:[{},{},{},{},{},{},{},{}], //奖品
      reward: 0,
      styleOpt:{
        prizeBgColors: [
          "#fadfff",
          "#fff7df",
          "#fadfff",
          "#fff7df",
          "#fadfff",
          "#fff7df",
          "#fadfff",
          "#fff7df",
        ],
        borderColor: "#fff7df",
      },
    });
    const router = useRouter();
    const methods = {

      /* 转盘开关 */
      spinShow(){
        state.spinPopup = true
        state.spinShowPopup = true
      },
      /* 开始转动 */
      startTurns(){
        if(!state.userToken && isBlank(state.userSpinTpye)){ //未登录 并且 未参加
          for (const ls of state.prizeList) {
              if(Number(ls.prizeName) === state.spinInfo.initialRewardAmount){
                state.prizeIndex = Number(ls.id);
              }
          }
          turntable.value.rotateTurn();
          return
        }else if(state.userToken && isBlank(state.userSpinTpye)){ //已登录 但 未参加
          for (const ls of state.prizeList) {
              if(Number(ls.prizeName) === state.spinInfo.initialRewardAmount){
                state.prizeIndex = Number(ls.id);
              }
          }
          turntable.value.rotateTurn();
          //
          return
        }
        if(state.spinInfo.participationStatus === "wait_receive"){
          // Dialog({
          //   content: 'Congratulations, you successfully received ₱1000 rewards!',
          //   okText: 'OK',
          //   noCancelBtn: true
          // });
          methods.receiveReward()
          return
        }else if(state.spinInfo.participationStatus === "received"){
          Dialog({
            content: 'You have successfully received the event rewards for this period, please wait for the next event!',
            okText: 'OK',
            noCancelBtn: true
          });
          return
        }else if(state.spinInfo.participationStatus === "ongoing"){
          if(state.spinInfo.availableSpin <= 0){
            methods.receiveReward()
            return
          }else if(state.spinInfo.availableSpin > 0){
            axios.post("/api/activity/spin/spin", {})
            .then(function (res) {
              if(res.data.code === 0) {
                state.spinInfo.availableSpin = res.data.data.availableSpin
                state.reward = res.data.data.reward
                for (const ls of state.prizeList) {
                    if(Number(ls.prizeName) === state.reward){
                      state.prizeIndex = Number(ls.id);
                    }
                }
                state.temp.participationStatus = res.data.data.participationStatus
                state.temp.rewardAmount = res.data.data.totalReward >= state.rewardAmountMax ? state.rewardAmountMax : res.data.data.totalReward  
                state.temp.percentage = parseInt((state.temp.rewardAmount / state.rewardAmountMax) * 100);
                turntable.value.rotateTurn();
              }
            })
          }
        }else{
          Dialog({
            content: 'Please Login!',
            okText: 'OK',
            noCancelBtn: true
          });
        }
      },
      /* 中奖 */
      endTurns(){

        if(!state.userToken && isBlank(state.userSpinTpye)){
          state.showFirstTurnsBox = true;
          localStorage.setItem(state.localStorageSeqNum,'firsting');
          //state.userSpinTpye = 'firsting'
        }else if(state.userToken && isBlank(state.userSpinTpye)){
          state.showOldUserFirstTurnsBox = true;
          localStorage.setItem(state.localStorageSeqNum,'ongoing');
          //state.userSpinTpye = 'ongoing'
        }else{
          state.spinInfo.participationStatus = state.temp.participationStatus
          state.spinInfo.rewardAmount = state.temp.rewardAmount
          state.percentage = state.temp.percentage

          state.temp.participationStatus = ''
          state.temp.rewardAmount = 0
          state.temp.percentage = 0

          state.showEndTurnsBox = true;
        }
      },
      /* 关闭新用户通知 */
      closeFirstTurnsBox(){
        state.showFirstTurnsBox2 = false;
        state.showOldUserFirstTurnsBox = false;
        localStorage.setItem(state.localStorageSeqNum,'ongoing');
        state.userSpinTpye = 'ongoing'
        state.spinInfo.participationStatus = "ongoing"
      },
      /* 关闭最终奖励通知 */
      rewardsReceivedSuccess(){
        state.rewardsReceivedSuccessBox = false
        state.spinPopup = false
        state.spinShowPopup = false
        state.spinActivityStatus = false
        router.push({ path: '/agentPromotion' });
      },
      //验证领奖
      showReceiveReward(){
        methods.receiveReward()
      },
      /* 领取单次奖励 */
      receiveTurnsReward(){
        state.showEndTurnsBox = false;
      },
      /* 领取最终奖励 */
      receiveReward(){
        if(state.spinInfo.participationStatus === "ongoing"){
          state.showTaskList = true
        }else if(state.spinInfo.participationStatus === "wait_receive"){
          //领奖
          axios.post("/api/activity/spin/receive-reward", {})
          .then(function (res) {
            if(res.data.code === 0) {
              //领取成功
              state.rewardsReceivedSuccessBox = true
            }
          })
        }else if(state.spinInfo.participationStatus === "received"){
          //已经领取
          Dialog({
            content: 'You have successfully received the event rewards for this period, please wait for the next event!',
            okText: 'OK',
            noCancelBtn: true
          });
        }
        
      },
      lobbyTabClick(type){
        state.lobbyTabType = type
      },
      hideRegStoreCoin(){
        state.regCoing = false
        localStorage.removeItem('regStoreCoin')
      },

      gameTabsClick(val){
        switch (val.title) {
          case 'Slots':
            state.jiliGamesSlots = state.jiliGamesSlotsData
            return 'r';
          case 'Poker':
            state.jiliGamesPoker = state.jiliGamesPokerData
            return 'r';
          case 'Casino':
            state.jiliGamesCasino = state.jiliGamesCasinoData
            return 'r';
          case 'Fishing':
            state.jiliGamesFish = state.jiliGamesFishData
            return 'r';
          case 'Popular':
            state.jiliGamesOther = state.jiliGamesOtherData
            return 'r';
        }
      },
      toFoot(){
        this.$nextTick(() =>{
          this.$refs.mainScroll.scrollIntoView({
            behavior: 'smooth',  
            block: 'end',  
            inline: 'nearest'  
          })
        })
      },
      toTelegram(){
          Toast.loading("Loading", {
            id: "loading",
            duration: 0,
            bgColor: "rgba(0, 0, 0, 0.9)",
            cover: true,
          });
          const commonInfo = JSON.parse(window.localStorage.getItem("commonInfo"));
          window.open(commonInfo.telegramChannelUrl,"_self");
      },
      rechargeBtnClick() {
        router.push({ path: '/rechargeBalance' });
      },
      toGamesList(t){
        let _type = t ? t : 'all'
        router.push({
          path: '/glist',
          query: {
            tabType: _type
          }
        })
      },
      toRegister(){
        router.push({ path: '/userRegister' });
      },
      toInvite(){
        router.push({ path: '/agentPromotion' });
      },
      toDeposit(){
        router.push({ path: '/rechargeBalance' });
      },
      toPlay(){
        router.push({ path: '/glist' });
      },
      toHelp() {
        router.push({ path: '/helpSupport' });
      },
      toReferBonus(){
        router.push({
          name: "referBonus",
        });
      },
      toLiveGame(id, platform, name){
        let _id = id
        let _name = name
        let _platform = platform
        router.push({
          path: 'liveGame',
          query: {
            id: _id,
            name: _name,
            platform: _platform
          }
        })
      },
      toGameHome(id, platform, name){
        let _id = id
        let _name = name
        let _platform = platform
        if(platform === 'lobby'){
          //setLastTimeGameName(_id,'lobby')
          router.push({ path: '/' + _id });
        }else if(platform === 'jili'){
          methods.jiLiFreeTrial(_id, _platform, _name)
        }else{
          //setLastTimeGameName(_id, _platform)
          router.push({
            path: 'playGame',
            query: {
              id: _id,
              name: _name,
              platform: _platform
            }
          })
        }
      },
      /* 试玩弹窗 */
      jiLiFreeTrial(id, platform, name){
        state.jiliLink = true
        state.jiliGameUrl =  "https://jiligames.com/plusplayer/PlusTrial/" + id + "/en-US";
        state.jiliGameQuery.id = id
        state.jiliGameQuery.platform = platform
        state.jiliGameQuery.name = name
      },
      jiLiplayGame(){
        router.push({
            path: 'playGame',
            query: {
              id: state.jiliGameQuery.id,
              name: state.jiliGameQuery.name,
              platform: state.jiliGameQuery.platform
            }
          })
          state.jiliLink = false
      },
      jiLiDemoGame(){
        methods.textToast(_data.data.i18n.loading);
        window.open(state.jiliGameUrl, '_self') // _self _blank
        state.jiliLink = false
      },
      showLastGameList(){
        state.myLastTimeGameList = JSON.parse(localStorage.getItem('myLastTimeGameList'))
        for(let i in state.myLastTimeGameList){
          let _gameId = state.myLastTimeGameList[i]
          let _platform = searchPlatform(_gameId)
          let d = {
            gameId : _gameId,
            platform : _platform,
            name: '',
          }
          state.lastGameList.push(d)
        }
      },
      toMyHome(){router.push({name: "myHome"});},
      toAgentPromotion(){router.push({name: "agentPromotion"});},
      toTaskReward(){router.push({name: "taskReward"});},
      rechargeClick() {router.push({name: "rechargeBalance"});},
      userLoginClick() {router.push({name: "userLogin"});},
      registerClick() {router.push({name: "userRegister"});},

      withdrawalClick(t) {
        router.push({
          path: 'withdrawalBalance',
					query: {
						amountType: t
					}
				})
      },
      textToast(msg) {
        Toast.loading(msg, {
          id: "loading",
          duration: 0,
          bgColor: "rgba(0, 0, 0, 0.9)",
          cover: true,
        });
      }
    };
    onMounted(() => {
      methods.textToast(_data.data.i18n.loading);
      state.spinPopup = false
      axios
        .get("/api/game/lobby", {})
        .then(function (res) {
          for (const key in res.data.data.games) {
            if(res.data.data.games[key].promote && state.promoteGameList.length < 8){
              state.promoteGameList.push(res.data.data.games[key])
            }
            if(res.data.data.games[key].newGame && state.newGameList.length < 8){
              state.newGameList.push(res.data.data.games[key])
            }
            if (res.data.data.games[key].platform === 'lobby') {
              state.lobbyGames.push(res.data.data.games[key])
            }else if(res.data.data.games[key].platform === 'jili' || res.data.data.games[key].platform === 'cq9'){
              if(res.data.data.games[key].type === 'slots' && state.jiliGamesSlotsData.length < 13){
                state.jiliGamesSlotsData.push(res.data.data.games[key])
              }else if(res.data.data.games[key].type === 'casino' && state.jiliGamesCasinoData.length < 13){
                state.jiliGamesCasinoData.push(res.data.data.games[key])
              }else if(res.data.data.games[key].type === 'fish' && state.jiliGamesFishData.length < 13){
                state.jiliGamesFishData.push(res.data.data.games[key])
              }else if(res.data.data.games[key].type === 'poker' && state.jiliGamesPokerData.length < 13){
                state.jiliGamesPokerData.push(res.data.data.games[key])
              }else if(res.data.data.games[key].type === 'other' && state.jiliGamesOtherData.length < 13){
                state.jiliGamesOtherData.push(res.data.data.games[key])
              }
            }
          }
          state.jiliGamesSlots = state.jiliGamesSlotsData
          if(isBlank(localStorage.getItem('firstVisit'))) {
            localStorage.setItem("firstVisit", 'lobby')
          }
          state.winRank = res.data.data.winRank
          if(localStorage.getItem("token")){
            state.showLogin = (!isBlank(res.data.data.user)) ? false : true
            state.userInfo.mobile = res.data.data.user.mobile;
            state.userInfo.inviteCode = res.data.data.user.inviteCode;
            state.userInfo.avatar = 
            require('../assets/images/avatar/' + res.data.data.user.avatar.slice(res.data.data.user.avatar.length-6))
            state.userInfo.vipLevel = res.data.data.user.vipLevel;
            state.userInfo.hasPassword = res.data.data.user.hasPassword;
            state.userInfo.availableAmount = res.data.data.fundAccount.availableAmount;
            state.userInfo.chipAmount = res.data.data.chipAccount.availableAmount;
          }else{
            state.showLogin = true
          }
          Toast.hide("loading");
          state.loadingCompleted = true 
        })
        .catch(function (error) {
          console.log(error);
          Toast.hide("loading");
        })
        methods.showLastGameList()
        state.regCoing = localStorage.getItem("regStoreCoin") > 0 ? true : false
        //转盘
        axios
        .get("/api/activity/spin/info", {})
        .then(function (res) {
          state.spinInfo = res.data.data
          if(isNotBlank(state.spinInfo.activityStatus) && state.spinInfo.activityStatus === 'enable'){

            //设置本期参与用户类型
            state.localStorageSeqNum = 'userSpinTpye_' + state.spinInfo.seqNum
            state.userSpinTpye = localStorage.getItem(state.localStorageSeqNum)
            //设置本期初始转动
            if(state.userToken){ //用户已经登录
              if(isBlank(state.userSpinTpye)){
                state.spinPopup = true

              }else if(state.userSpinTpye === 'firsting'){ //新用户注册成功 进入大厅
                state.spinPopup = true
                state.showFirstTurnsBox2 = true
              }else if(state.userSpinTpye === 'ongoing'){ //用户进入正常流程
                console.log("ok")
              }
            }else if(isBlank(state.userSpinTpye)){ //用户没登录 并且 没参加过本期
              state.spinPopup = true
            }
            //设置获得总开关
            state.spinActivityStatus = true
            //设置结束时间
            state.spinInfo.endTime = state.spinInfo.endTime.substring(0,10)
            //设置当前进度
            state.percentage = parseInt((state.spinInfo.rewardAmount / state.rewardAmountMax) * 100);
            //设置奖品列表
            let _prizeList = state.spinInfo.showConf.split(",")
            for(var i in _prizeList){	
              state.prizeList[i] = {
                id: i,
                prizeName: _prizeList[i],
                prizeImg:require('../assets/images/global/bg514.png'),
                prizeColor: "#fadfff",
              }
            }
            
            //设置倒计时
            if(isNotBlank(state.spinInfo.endTime)){
              let et  = res.data.data.endTime.slice(6,10) + '-' + res.data.data.endTime.slice(3,5) + '-' + res.data.data.endTime.slice(0,2) + ' ' + '00' + ':' + '00' + ':' + '00' 
              state.countDown = Date.now() + (Date.parse(et) - Date.now())
            }
            //设置下注任务
            if(isNotBlank(state.spinInfo.betChanceConf)){
              state.spinInfo.betChanceConf = state.spinInfo.betChanceConf.split(',')
            }
            //设置邀请任务
            if(isNotBlank(state.spinInfo.inviteChanceConf)){
              state.spinInfo.inviteChanceConf = state.spinInfo.inviteChanceConf.split(',')
            }
            //设置充值任务
            if(isNotBlank(state.spinInfo.rechargeChanceConf)){
              state.spinInfo.rechargeChanceConf = state.spinInfo.rechargeChanceConf.split(',')
            }
            
          }else{
            state.spinActivityStatus = false
          }

        })

    });
    return {
      turntable,
      ...toRefs(state),
      ...methods,

    };
  },
};
</script>

<style scoped>

.icon-b{ background: #00eda6 url(../assets/images/global_icon/202405-23.png) 2px 5px no-repeat; background-size:auto 18px; display: inline-block;width: 90px; height: 32px; line-height: 32px; 
  vertical-align: super;  font-size: 12px; text-align: center; border-radius:0 17px 17px 0; text-align: left; padding-left: 24px; margin-top: 3px;  }
.icon-b a{text-decoration: none; font-weight: 500; color: #001f3b; font-size: 12px; }

.index{ min-width: 340px; margin: 0 auto; background:#110E1B; padding-bottom: 50px;}
.inx-top { position: fixed;top: 0; left: 0;right: 0;height: 40px;z-index: 2000;
  background:linear-gradient(135deg, #1c2636 0%, #1a2235 51%) !important;box-shadow: 0 1px 7px #170126;}
.inx-top .l { width: 45%;float: left; line-height: 40px; height: 40px; color: #fff; font-size: 14px;}
.inx-top .r {width: 45%; float: right;line-height: 40px; height: 40px; text-align: right;}
.inx-top .r i { width: 16px;height: 16px;line-height: 18px;border: 2px solid #fff; display: inline-block; font-weight: 600;
  border-radius: 20px;font-size: 16px;color: #fff;text-align: center;font-style: normal; margin: 3px 10px 0 0;opacity: .7;}
.inx-hd{ min-height: 40px; padding:8px 10px 0 10px; display: flex; width: 100%;  background:linear-gradient(180deg, #1c2636 0%, #000 51%) !important;}
.inx-hd .avatar-r{display: flex;justify-content: space-between; }



.avatar-l{ width: 35px; height:35px; border-radius:50%; margin-top:0px; border: 2px solid #eab000;}
.avatar-l img{ width: 35px;}

.btn-r{margin-top: 0px; padding-left:6px; width: calc(100% - 42px); display: flex; justify-content: space-between;}

.inx-hd .btn-r .s1{width: 90px;}

.inx-hd .btn-r .s3{width: calc(100% - 220px);padding-top: 2px;}
.inx-hd .btn-r .s2{width: 120px;}
.inx-hd .btn-r .s2 .tx{ color: #fff189; font-size: 10px; margin-top: -5px;}
.btn-r span{font-size: 12px; display: block;color: #fff; opacity: .8; line-height: 10px;}
.btn-r strong{ height: 30px;line-height: 30px; color: #f7c929; font-size: 17px; margin-bottom: 0px; display: block;}
.btn-r strong span{vertical-align: middle;display: inline-block; height: 16px;}
.btn-r strong span .nut-icon{width: 25px; height: 9px; line-height: 20px;}




.inx-body{ margin: 10px; background: #1c2636; border-radius:10px;}

.inx-fd{ min-height: 150px;  background: #1a2235;border-radius:15px; overflow: hidden; margin: 10px; padding-bottom: 0px; }
.inx-fd h4{border-top: 0px; margin: 0 10px; color: #fff; position: relative;}
.inx-fd h4 .icon-last{opacity: .6;}
.inx-fd h4.last-game-list .icon-hot{ opacity: .6;}
.inx-fd h4.last-game-list .icon-last{opacity: 1;}


.inx-tg-btn{ position: absolute; right: 0px; top: 4px; }
.inx-tg-btn img{ height: 32px; width: auto;}


.link-img{ margin-bottom: 5px; position: relative;}
.link-img img{ width: 100%;}

.inx-new{  margin: 0 10px 10px 10px; background: #1a2235; border-radius: 15px;background: linear-gradient(90deg, #eb02e2, #2797ff); padding: 2px; overflow: hidden;}
.inx-new .bd{background: #1c2636;height: 130px;border-radius: 15px; display: flex;}
.inx-new .bd .l { background: #110E1B; width: 120px; text-align: center; height: 110px;  margin-top: 10px; margin-left: 10px;border-radius: 15px;}
.inx-new .bd .l img{ width: 110px; height: auto;}
.inx-new .bd .r{ color: #fff; margin-left: 20px;}
.inx-new .bd .r h4{ margin: 5px 0 0px 0; font-size: 20px;}
.inx-new .bd .r strong{ color: #b2c3e3; font-weight: 300; line-height: 16px; font-size: 14px;}
.inx-new .bd .r .btn{background: linear-gradient(90deg, #eb02e2, #2797ff); width: 120px; padding: 3px; text-align: center;border-radius: 30px; margin-top: 10px; }
.inx-new .bd .r .btn strong{ color: #FFE114; background: #110E1B; display: block; line-height: 32px; border-radius: 30px; font-size: 20px;font-weight: 700;}

.index-nav { display: flex; justify-content: space-between; flex-wrap: wrap; margin:0 10px 10px 10px;}
.index-nav div{ width: 32.8%; background: #1a2235; border-radius: 5px; color: #fff; height: 45px;}
.index-nav div.s1{background:#1a2235 url(../assets/images/global_icon/202405-26.png) 5px center no-repeat; background-size:35px auto; }
.index-nav div.s2{background:#1a2235 url(../assets/images/global_icon/202405-28.png) 5px center no-repeat; background-size:35px auto; }
.index-nav div.s3{background:#1a2235 url(../assets/images/global_icon/202405-29.png) 0px center no-repeat; background-size:35px auto; }

.index-nav div.s3 strong{padding-left: 43px; }
.index-nav div.s3 span{padding-left: 43px;font-size: 10px;}


.index-nav strong{ display: block; line-height: 20px; padding-left: 45px; margin-top:2px; font-size: 14px; margin-bottom:0px;}
.index-nav span{ display: block; line-height: 10px;padding-left: 45px;font-size: 10px; color: #b2c3e3;}

.inx-fd h4{ margin: 0; padding: 2px 5px 2px 15px; line-height: 26px; color: #fff; font-size: 14px;
  background:linear-gradient(89deg,#0e3d9c .38%,#199082 107.78%);position: relative;}
.inx-fd h4 .icon-hot{background:#052536 url(../assets/images/global_icon/202405-36.png) 5px center no-repeat; background-size:24px; padding:3px 20px 3px 30px; border-radius: 20px; }
.inx-fd h4 .icon-last{background:#14d4ff url(../assets/images/global_icon/202405-38.png) 10px center no-repeat; background-size:20px; color: #000;
  padding: 0px 20px 0px 35px;border-radius: 20px 20px 0px 20px; margin-left: 10px;float: right;}

.inx-fd h4 .icon-top{background:url(../assets/images/global_icon/202405-27.png) left center no-repeat; background-size:24px; padding-left: 24px; }


.lobby-tab h4{ background: #110E1B !important; padding:0; display: flex;}
.lobby-tab strong{background: linear-gradient(89deg,#201a36 .38%,#6d00c1 107.78%); color: #f4dcff;
  padding:8px 0px; text-align: center; border-radius: 10px 50px 0 0; margin: 0 2px 0 0; width: calc(33% - 2px); font-size: 15px; line-height: 18px;}
.lobby-tab strong:last-child{margin: 0 0 0 0px;width: calc(33% + 4px);}
.lobby-tab strong.a{ background: linear-gradient(89deg,#201a36 .38%,#fa00ff 107.78%); color: #fff200;}



.top-10{  padding-bottom: 5px;padding: 0 10px; }
.top-10 li{padding: 10px 0; margin: 0 2px; line-height: 30px; display: flex; border-top: 1px solid rgba(88, 72, 139, .4);;}
.top-10 li img{ width: 30px; height: 30px; border-radius:50%; margin: 0 10px;}
.top-10 li i{border-radius:13px; width: 26px; height: 26px; background: #00EDA6; color: #000; text-align: center;line-height: 28px;font-size: 16px; font-weight: 600; overflow: hidden; margin-top: 3px;}
.top-10 li span{ font-size: 12px; color: #B6BDCC;}
.top-10 li strong{ margin-left: auto; color:#FFE114; font-weight: 600;font-size: 16px;}


.link-2x{ display: flex; justify-content: center; flex-wrap: wrap; justify-content: flex-start; margin: 10px 0 0 0; width: 100%;}
.link-2x .link-i{ width: 44%; position: relative;margin:0 3%}
.link-2x .link-i img{ width: 100%;}


.link-3x{ display: flex; justify-content: center; flex-wrap: wrap; justify-content: flex-start; margin: 20px 0 0 0; width: 100%;}
.link-3x .link-i{ width: 33.3%; position: relative;margin:0}
.link-3x .link-i img{ width: 100%;}

.inx-fd .link-3x{ margin-top: 5px;}
.inx-fd .link-3x .link-i{ width: calc(25% - 10px); margin:0 5px}
.inx-fd .link-3x .link-i img{ border-radius: 10px; overflow: hidden;}

.coming-soon{ position: absolute; bottom: 22px; left: 0 ; right: 0;  text-align: center; height: 30px; line-height: 24px;
background: url(../assets/images/game_wheel/bg201.png) 0 0  no-repeat; background-size:100% 30px; color: #fff;font-size: 15px; font-weight: 600;}
.to-foot{ position: fixed; right: 0px; bottom: 50px; width: 50px; height: 40px;border-radius:20px 0 0 20px;
  background:#007aed url(../assets/images/global_icon/202405-41.png) 12px 3px no-repeat; background-size:auto 80% ;}
.to-help{position: fixed;right: 0px; bottom: 50px; width: 44px; height: 44px;border-radius:30px 0 0 30px;
  background: url(../assets/images/global_icon/202405-66.png) 0px 0px no-repeat; background-size:44px auto;}
 
  .inx-bn { padding: 40px 0 0 0; margin: 0; width: 100%; position: relative; overflow: hidden;}
  .inx-bn img { width: calc(100% - 0px); height: auto; }


  .task-list{ position: absolute; top: 0; bottom: 0; left: 0; right: 0; background: url(../assets/images/global/bg510b.png); padding-top: 25px;}
.l-btn-box{ text-align: left; position: relative;}
.l-btn-box p{ height: 45px; line-height: 20px; color: #fff; padding: 8px 0 0 0; margin:0; font-size: 14px;}
.l-btn-box p span{ padding: 0px 15px; background: #0e3183; opacity: .5;font-size: 12px;border-radius: 10px;}
.l-btn-box .b-btn{ width: 320px; margin: 0 auto 8px auto; height: 90px; background:url(../assets/images/global/bg519.png) top center no-repeat; background-size: 100% 100%; position: relative;}
.l-btn-box .b-btn:nth-child(2){ background:url(../assets/images/global/bg517.png) top center no-repeat; background-size: 100% 100%;}
.l-btn-box .b-btn:nth-child(3){ background:url(../assets/images/global/bg518.png) top center no-repeat; background-size: 100% 100%;}
.l-btn-box .b-btn .bg1{ padding-left: 80px; background:url(../assets/images/global_icon/202405-53.png) 10px 10px no-repeat; background-size: 60px auto;}
.l-btn-box .b-btn .bg2{ padding-left: 80px;background:url(../assets/images/global_icon/202405-59.png) 10px 10px no-repeat; background-size: 60px auto;}
.l-btn-box .b-btn .bg3{ padding-left: 80px;background:url(../assets/images/global_icon/202405-54.png) 10px 10px no-repeat; background-size: 60px auto;}
.l-fd{ width: 300px; margin: 0 auto;}
.l-fd .l-btn{ width: 100%; margin: 0px auto 5px auto; border-bottom:5px solid #ac580a !important;   height: 48px !important; font-size: 16px;
  background: transparent linear-gradient(180deg,#fff,#f7c163 50%,#f2b03e) 0 0 no-repeat padding-box !important;  color: #8a5a1e !important;}
  .l-fd .l-btn span{ color: #8a5a1e; font-size: 16px; }
  .l-fd .l-btn strong{ color: #b93e00;font-size: 24px;}
.l-btn-box .b-btn .l-btn{ width: 150px; margin:0; border-bottom:3px solid #ac580a !important;   height: 32px !important; font-size: 14px;
  background: transparent linear-gradient(180deg,#fff,#f7c163 50%,#f2b03e) 0 0 no-repeat padding-box !important;}
  .task-list .l-tx{ margin:0 auto 10px auto; width: 320px;}
  .task-list .nut-progress{ width: 320px; margin: 0 auto;}
  .task-list .d1{ text-align: center; padding: 14px 0 10px 0; height: 30px; }
  .task-list .d1 strong{ font-size: 40px; height: 32px; font-weight: 600; color: #f7c163; line-height: 32px; padding-top: 3px;}
  .task-list .d1 p{ height: 40px;  padding: 0; color: #fff; font-weight: 600; line-height: 12px; font-size: 10px; margin: 0 auto 5px auto;}
  .task-list .d1 .tx{ color: #ac580a;font-size: 20px;}
  .task-list .d1 .tx strong{ font-size: 20px;color: #f7c163; }
  .task-list .nut-button--round{ border-radius: 6px !important;}
  .task-list .l-btn-box .close-btn{ right: calc(50% - 17px); top: auto; bottom: -50px;}

  .task-list .ico{ position: absolute; bottom: 32px; right: 5px; width: 160px; height: 28px; background: url(../assets/images/global/bg523.png) -160px 0 no-repeat; background-size: auto 17.2px;}
  .task-list .ico-t1{background: url(../assets/images/global/bg523.png) -144px 0 no-repeat; background-size: auto 17.2px;}
  .task-list .ico-t2{background: url(../assets/images/global/bg523.png) -128px 0 no-repeat; background-size: auto 17.2px;}
  .task-list .ico-t3{background: url(../assets/images/global/bg523.png) -112px 0 no-repeat; background-size: auto 17.2px;}
  .task-list .ico-t4{background: url(../assets/images/global/bg523.png) -96px 0 no-repeat; background-size: auto 17.2px;}
  .task-list .ico-t5{background: url(../assets/images/global/bg523.png) -80px 0 no-repeat; background-size: auto 17.2px;}
  .task-list .ico-t6{background: url(../assets/images/global/bg523.png) -64px 0 no-repeat; background-size: auto 17.2px;}
  .task-list .ico-t7{background: url(../assets/images/global/bg523.png) -48x 0 no-repeat; background-size: auto 17.2px;}
  .task-list .ico-t8{background: url(../assets/images/global/bg523.png) -32px 0 no-repeat; background-size: auto 17.2px;}
  .task-list .ico-t9{background: url(../assets/images/global/bg523.png) -16px 0 no-repeat; background-size: auto 17.2px;}
  .task-list .ico-t10{background: url(../assets/images/global/bg523.png) -0px 0 no-repeat; background-size: auto 17.2px;}



</style>
<style>
.btn-r .s3 .inx-btn{ background: #363222 !important; color: #f7c929 !important; border: 0px solid #666 !important;  
min-width: 80px !important; width: 80px !important;height: 25px;padding:0; margin-right: 3px;}

.l-main .nut-progress .nut-progress-outer{background-color: #737373 !important;}
.l-main .nut-countup{ width: 30px; color: #26ff00; padding: 0px;font-size: 25px;}

.l-main .l-fd .l-btn .nut-countup {color: #b93e00; font-size: 24px;  width: auto;letter-spacing:0; vertical-align: middle;}
</style>
