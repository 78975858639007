<template>
  <div>
    <nut-navbar
    :left-show="false"
    :title="$t('invite.h1')"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed"
    >
    <template #left>
        <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
      </template>
    </nut-navbar>
  
    <div class="login data">
  
      <div class="login-bd">
  
        <nut-cell title="Start time" :desc="desc_s" @click="startTimePopup = true"></nut-cell>
        <nut-datepicker
            v-model="startTime"
            title="Select Start time"
            type="datetime"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="startTimeConfirm"
            v-model:visible="startTimePopup" 
        ></nut-datepicker>

        <nut-cell title="End time" :desc="desc_e" @click="endTimePopup = true"></nut-cell>
        <nut-datepicker
            v-model="endTime"
            title="Select end time"
            type="datetime"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="endTimeConfirm"
            v-model:visible="endTimePopup" 
        ></nut-datepicker> 


        <div class="login-btn">
          <nut-button block type="info" @click="getInviterData">{{$t('global.confirm')}}</nut-button>
        </div>
  
      </div>
  
    </div>

    <div class="data-ls" v-show="dataList" style="border-radius: 10px 10px 0 0; margin-bottom: 2px;">
      <div class="t" style="width: 50%;">
        <label>Invite count</label>
        <strong>{{ inviteCount }}</strong>
      </div>
      <div class="t" style="width: 50%;">
        <label>Effective Count</label>
        <strong class="g">{{ effectiveCount}}</strong>
      </div>
    </div>


    <div class="data-ls" v-show="dataList" style="border-radius:  0 0 10px 10px; margin-top: 0px;">
      <div class="t">
        <label>Recharge count</label>
        <strong>{{ rechargeCount }}</strong>
      </div>
      <div class="t">
        <label>Recharge Amount</label>
        <strong>{{ $filters.currencySymbol(rechargeAmount) }}</strong>
      </div>
      <div class="t">
        <label>Withdraw count</label>
        <strong class="g">{{ coinWithdrawCount }}</strong>
      </div>
      <div class="t">
        <label>Withdraw Amount</label>
        <strong class="g">{{ $filters.currencySymbol(coinWithdrawAmount) }}</strong>
      </div>
    </div>

    

  
  </div>
  </template>
  
  <script>
  import { onMounted } from "vue";
  import { useRouter } from "vue-router";
  import { Toast } from "@nutui/nutui";
  import axios from "axios";

  export default {
    data() {
      return {
        dataList: false,
        startTimePopup: false,
        endTimePopup: false,
        startTime:new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
        endTime: new Date(),
        minDate: new Date(2020, 0, 1),
        maxDate: new Date(),
        desc_s: this.formatDateTime(new Date(),'start'),
        desc_e: this.formatDateTime(new Date(),'end'),
        rechargeCount:0,
        rechargeAmount:0,
        coinWithdrawCount:0,
        coinWithdrawAmount:0,
        inviteCount:0,
        effectiveCount:0,
        startTimeConfirm : ( { selectedValue} )=>{
          let d = selectedValue.slice(0, 3).join('-');
          let t = selectedValue.slice(3).join(':');
          this.startTime = d + ' ' + t;
          this.desc_s = this.startTime
        },
        endTimeConfirm : ( { selectedValue} )=>{
          let d = selectedValue.slice(0, 3).join('-');
          let t = selectedValue.slice(3).join(':');
          this.endTime = d + ' ' + t;
          this.desc_e = this.endTime
        }
      }
    },
    methods: {
      formatDateTime(date,type) {
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        if(month < 10){month = '0' + month}
        let day = date.getDate();
        if(type === 'start'){day--}
        if(day < 10){day = '0' + day}
        let hour = date.getHours();
        if(hour < 10){hour = '0' + hour}
        let minute = date.getMinutes();
        if(minute < 10){minute = '0' + minute}
        //const second = date.getSeconds();
        return year + '-' + month + '-' + day + ' ' + hour + ':' + minute
      },
      getInviterData() {
        
        if(this.startTime === ""){
          Toast.fail('Please select start time!');
          return
        }else if(this.endTime === ""){
          Toast.fail('Please select end time!');
          return
        }else {
          this.startTime = this.desc_s
          this.endTime = this.desc_e
          Toast.loading('Loading...', {
            id: "loading",
            duration: 0,
            bgColor: "rgba(0, 0, 0, 0.9)",
            cover: true,
          });
          axios.post("/api/invite/special-inviter-page", {
              endTime: this.endTime + ':00',
              startTime: this.startTime + ':00'
            })
            .then((response) => {
              if (response.data.code === 0) {
                this.dataList = true
                this.rechargeCount = response.data.data.rechargeCount;
                this.rechargeAmount = response.data.data.rechargeAmount;
                this.coinWithdrawCount = response.data.data.coinWithdrawCount;
                this.coinWithdrawAmount = response.data.data.coinWithdrawAmount;
                this.inviteCount = response.data.data.inviteCount;
                this.effectiveCount = response.data.data.effectiveCount;
              }
              Toast.hide('loading');
            })
        }
      }
    },

    setup() {
      const router = useRouter();
      const methods = {
        backClick() {
          router.push({path: "myHome"});
        },
      };
        onMounted(() => {
      });
      return {
        ...methods,
      };
    },
  };
  </script>
  
<style scoped>
  .data { padding-top: 40px;}
  .data .login-inp{ padding:8px 10px; background: #fff; margin-bottom: 10px; border-radius: 10px; display: flex; justify-content:space-between}
  .data .login-inp label{  line-height: 30px; height: 30px; margin:0; }
  .data .login-inp span{   line-height: 30px; height: 30px; color: #999;}
  .data .login-bd{ margin-top: -5px; padding-top: 25px; padding-bottom: 8px;}

.data-ls{ height: 75px; padding: 10px 0; display: flex; color: #fff; text-align: center;
  background: #1a2235 url(../assets/images/global/img10.png) center top no-repeat; background-size: 150% auto;
  margin: 10px;border-radius: 10px;}
.data-ls .t{ width: 25%;}
.data-ls .t label{ line-height: 16px; display: block; margin-bottom: 5px; padding: 0 5px; height: 32px;}
.data-ls .t strong{ display: block; line-height: 40px; color: #ffee00; font-size:18px;}

.data-ls .t strong.g{ color: #81ff4b;}
</style>
 <style>
  .data .login-bd .nut-cell__value{ color: #447cb1 !important;}
</style>