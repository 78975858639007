<template>
  <div>
    <nut-navbar
    :left-show="false"
        @on-click-icon="iconClick"
        titIcon="horizontal"
        :title="$t('withdrawal.h1')"
        fixed=true
        class="mb0 nav-icon-right nut-navbar--fixed"
    >
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
    </nut-navbar>

    <div class="recharge-bd withdrawal-bd">

      <div class="top-info-text">
        <nut-noticebar 
          direction='vertical' 
          :list="othersInfo" 
          :height='30' 
          :speed='3' 
          :standTime='1000' 
          :closeMode="true" 
          :color="`#000`">
        </nut-noticebar>
      </div>


      <div class="top-text">
        <strong>{{$t('withdrawal.availableBalance')}}</strong>
        <br/>
        <span class="f-24">{{ availableAmount }}</span>
        
        <div class="top-text-b">
          {{ amountType === 'cash' ? $t('withdrawal.agentAmount') : $t('withdrawal.gameAccount') }}
        </div>
      </div>
      

      <div class="recharge-ls">
        <div
            class="recharge-btn"
            @click="rechargeBtnClick(item.withdrawAmount, index, item.enable)"
            v-for="(item, index) in plans"
            :key="index"
            :class="{ active: isActive === index, btnDisabled: !item.enable || item.withdrawAmount < allowRange.min || item.withdrawAmount > allowRange.max || (item.withdrawAmount > withdrawSingleLimitAmount && amountType !== 'cash') }" >
          <strong>{{ $filters.currencySymbol(item.withdrawAmount) }}</strong>
          <div class="icon-help"></div>
        </div>
      </div>

      <nut-form class="label-w150">
        <nut-form-item :label="$t('withdrawal.withdrawAmount')">
          <input
              class="nut-input-text"
              type="text"
              v-model="withdrawAmount"
              v-on:input="inputChange"
              :readonly="!allowCustomAmount"
          />
        </nut-form-item>

        <div v-show="inpShow">
          <nut-form-item :label="$t('withdrawal.handlingFee')">
            <input
                class="nut-input-text"
                type="text"
                v-model="feeAmount"
                readonly="true"
                style="width:40%" />
          </nut-form-item>

          <nut-form-item :label="$t('withdrawal.receiptAmount')">
            <input
                class="nut-input-text"
                type="text"
                v-model="receiptAmount"
                readonly="true"
            />
          </nut-form-item>

          <nut-form-item :label="$t('withdrawal.bankCard')">
            <nut-radiogroup direction="horizontal" v-model="radio">
              <nut-radio
                  :label="item.id"
                  :id= "index === 0 ? 'radioaActive' : ''"
                  v-for="(item, index) in bankList"
                  :key="index"
                  @click="bankIdRadio(item.id)"
                  :checked="{ checked: index === 1 }"
              >
                <span>( ***{{ item.bankInfo.accountNum.slice(-4) }} )</span>
              </nut-radio>
            </nut-radiogroup>
          </nut-form-item>
        </div>
      </nut-form>

      <div class="login-btn m10">
        <nut-button block type="info" @click="withdrawalSubmit"
                    :disabled="!inpShow || btnDisabled"
        >{{$t('withdrawal.withdrawalBtn')}}
        </nut-button
        >
      </div>

      <div class="top-text-b top-text-b2" @click="withdrawCount" v-if="dailyCanWithdrawCount <= 0 && amountType !== 'cash'">
          Today’s remaining times are <strong>{{ dailyCanWithdrawCount }}</strong>
        </div>
      
      <div class="bt-tx">
        <p><strong>{{$t('withdrawal.description')}}</strong></p>
        <p>
          1、According to the government regulatory requirements, to prevent money laundering, 
          before withdrawing cash, you must meet the <strong style="color: #00EDA6;">withdrawal amount/valid bet amount ≤ 1.(Bet data may be delay,max 60s)</strong>
          When triggering the limit, the platform will remind you of how many more valid bets you need to make to complete the withdrawal.
        </p>
        <p>
          2、Upgrade your VIP level to enjoy more rights and privileges. <strong style="color:#00EDA6" class="text-color" @click="toVipDetails">See VIP details</strong>.
        </p>
        <p>
          3、Please make sure your payment information is correct and protect your account password. 
          The platform will not be responsible for any loss caused by wrong payment information and leaked password.
        </p>

      </div>
    
    </div>



    <nut-popup pop-class="pop-vip-lobby" v-model:visible="vipPopup" :z-index="100">
      <VipRules :myLevel="myLv" :popTitle="popTitle" upgradeVip="show"></VipRules>
      <div class="login-btn m10">
        <nut-button block type="info" @click="toRecharge" >{{$t('recharge.levelUpVip')}}</nut-button>
      </div>
    </nut-popup>


    <nut-popup pop-class="pop-withdrawal-restrict" v-model:visible="showWithdrawalRestrict" :z-index="100" style="border-radius:8px;" >
      <div class="pop-bankLis">
        <div class="hd">
          {{ $t('withdrawal.errInfopPlayMore1') }}
          <strong>XXX</strong>
          {{ $t('withdrawal.errInfopPlayMore2') }}
        </div>
        <h6>Effective Bet Calculation:</h6>
        <div class="bd">
          <ul class="ls-ul">
            <li><span>Mine</span><strong>100%</strong></li>
            <li><span>Parity</span><strong>100%</strong></li>
            <li><span>Wheel</span><strong>100%</strong></li>
            <li><span>A&B</span><strong>100%</strong></li>
            <li><span>Dice</span><strong>100%</strong></li>
            <li><span>Crash</span><strong class="r">35%</strong></li>
          </ul>
        </div>
        <div class="btn">
          <nut-button type="primary" @click="showWithdrawalRestrict = false">{{ $t('global.cancelText') }}</nut-button>
        </div>
      </div>
    </nut-popup>
    

    <tabbar :activeIndex="tabbarActiveIndex"></tabbar>

    <nut-drag direction="y" :style="{ bottom: '60px', right:'5px', zIndex: '1000 !important'}"  @click="toHelp" >
      <div class="drag-wb-history">
        <img :src="require('../assets/images/global_icon/202405-66.png')"/>
      </div>
    </nut-drag>

  </div>
</template>

<script>
import {reactive, toRefs, onMounted, getCurrentInstance} from "vue";
import {useRouter, useRoute} from "vue-router";
import TabbarMain from "../components/TabbarMain.vue";
import axios from "axios";
import {Dialog, Toast} from "@nutui/nutui";
import VipRules from "../components/VipRules.vue";
import { isBlank } from '@/assets/js/commonUtil';
import { getCountry, getLastTimeGameName } from "@/assets/js/environment";

export default {
  components: {
    VipRules: VipRules,
    tabbar: TabbarMain,
  },
  data(){
    return{
      country:'',
      i18n:{
        loading: this.$t('global.loading'),
        okText: this.$t('global.okText'),
        errInfoBankCard: this.$t('withdrawal.errInfoBankCard'),
        errInfoRecharge: this.$t('withdrawal.errInfoRecharge'),
        errInfopPlayMore: this.$t('withdrawal.errInfopPlayMore'),
        submittedSuccess: this.$t('global.submittedSuccess'),
        popTitle_1: this.$t('recharge.popTitle_1'),
        popTitle_2: this.$t('recharge.popTitle_2'),
        successfully: this.$t('global.successfully'),
        rechargeBtnText: this.$t('global.withdrawalBtnText'),
        errInfoRequired: this.$t('global.errInfoRequired'),
        INVALID_INPUT_VALUE: this.$t('global.INVALID_INPUT_VALUE')
      }
    }
  },
  created() {
    this.country = getCountry()
  },
  setup() {
    const _data = getCurrentInstance();
    const state = reactive({
      commonInfo:'',
      eMail:'',
      telegramChannelUrl:'',
      vipPopup:false,
      popTitle:'',
      allowCustomAmount:true,
      tabbarActiveIndex: -1,
      amountType: '',
      radio: 1,
      isActive: "",
      withdrawAmount: 0,
      feeAmount: 0,
      receiptAmount: 0,
      availableAmount: 0,
      availableAmount2: 0,
      plans: [],
      myLv: null,
      bankList: [],
      showWithdrawalRestrict:false,
      availableBankList: [],
      bankId: "",
      inpShow: false,
      btnDisabled: false,
      othersInfo: [],
      othersInfoList: [],
      allowRange:{
        min: null,
        max: null
      },
      withdrawSingleLimitAmount: 0, //单次提现限额
      dailyCanWithdrawCount: 0, //每日可提现次数
      withdrawDailyLimitCount: 0
    });

    const router = useRouter();
    const route = useRoute();
    const methods = {
      telegramClick(){
        window.open(_data.data.telegramChannelUrl, "_self");
      },
      toPlay() {
        if(getLastTimeGameName()){
          router.push({
            path: '/lobby',
          });
        }else{
          router.push({
            path: '/lobby',
          });
        }
      },
      toHelp() {
        router.push({
          name: "helpSupport",
        });
      },
      toVipDetails(){
        router.push({
          name: "vipDetails",
        });
      },
      toRecharge() {
        router.push({
          name: "rechargeBalance",
        });
      },
      backClick() {
        router.go(-1);
      },
      iconClick() {
        router.push({
          path: "withdrawalHistoryList",
          query: {
            amountType: state.amountType
          }
        })
      },
      withdrawCount(){
        Dialog({
          title: 'Today’s remaining times are 0',
          content: 'As VIP' + state.myLv + ', you can withdraw ' + state.withdrawDailyLimitCount + ' times every day. You already withdraw ' + (state.withdrawDailyLimitCount - state.dailyCanWithdrawCount) + ' times today.Please continue to withdraw tomorrow. You can also Upgrade your VIP level to enjoy more rights and privileges. See VIP details.',
          okText: 'Got it',
          cancelText: 'VIP Details',
          onOk: () => {
          },
          onCancel: () => {
            router.push({
              name: "vipDetails",
            });
          }
        });
      },
      rechargeBtnClick(t, i, d) {
        if(state.amountType === 'coin'){
          if(t < state.allowRange.min || t > state.allowRange.max || t > state.withdrawSingleLimitAmount ){
            Dialog({
                // title: 'Order exception',
                content: 'As VIP' + state.myLv + ', the amount range for each withdrawal is ₱' + state.allowRange.min + '- ₱' + state.withdrawSingleLimitAmount + ', You can also Upgrade your VIP level to enjoy more rights and privileges. See VIP details.',
                okText: 'Got it',
                cancelText: 'VIP Details',
                onOk: () => {
                },
                onCancel: () => {
                  router.push({
                    name: "vipDetails",
                  });
                }
              });
            return
          }
          if(state.dailyCanWithdrawCount <= 0){
            methods.withdrawCount()
            return
          }
        }
        if(!d){return}
        if (i >= 0) {
          state.isActive = i;
        } else {
          state.isActive = ''
        }
        methods.textToast(_data.data.i18n.loading);
        state.withdrawAmount = t;
        axios({
          url: "/api/fund/withdraw/prepare",
          method: "get",
          params: {withdrawAmount: state.withdrawAmount}
        })
        .then((res) => {
          state.feeAmount = res.data.data.feeAmount;
          state.receiptAmount = res.data.data.receiptAmount;
          Toast.hide("loading");
          state.inpShow = true;
          setTimeout(() => {
            var e = document.createEvent("MouseEvents");
            e.initEvent("click", true, true);
            document.getElementById("radioaActive").dispatchEvent(e);
          }, 2000);
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      textToast(msg) {
        Toast.loading(msg, {
          id: "loading",
          duration: 0,
          bgColor: "rgba(0, 0, 0, 0.9)",
          cover: true,
        });
      },
      inputChange() {
        if(state.withdrawAmount <= 0 || state.withdrawAmount === null || state.withdrawAmount === ''){
          return
        }
        this.rechargeBtnClick(state.withdrawAmount, -1)
      },
      bankIdRadio(id) {
        state.bankId = id;
      },
      getBankList() {
        axios.get("/api/user/bank/list", {}).then(function (res) {
          if (res.data.data.length === 0) {
            Dialog({
              content: _data.data.i18n.errInfoBankCard,
              okText: _data.data.i18n.okText,
              noCancelBtn: true,
              onOk: () => {
                router.push({
                  path: "/addBankCard",
                  query:{target:'withdrawalBalance'}
                });
              }
            });
            return;
          }
          state.bankList = res.data.data
          state.bankId = res.data.data[0].id
        });
      },

      withdrawalSubmit() {

        state.btnDisabled = true;
        axios
            .post("/api/fund/withdraw/apply", {
              fundAccountType: state.amountType,
              applyAmount: state.withdrawAmount,
              userBankId: state.bankId,
            })
            .then(function (res) {
              let data = res.data;
              if (data.code === 2000) {
                if (data.bizCode === 'FUND_WITHDRAW_NEED_LEVEL_UP_VIP') {
                  state.vipPopup = true
                  state.popTitle= _data.data.i18n.popTitle_2
                }else if (data.bizCode === 'FUND_WITHDRAW_NEED_BET') {
                   //需要下注更多
                  state.showWithdrawalRestrict = true
                  // Dialog({
                  //   content: _data.data.i18n.errInfopPlayMore,
                  //   closeOnPopstate: true,
                  //   cancelText: 'OK',
                  //   onOk: () => {
                  //     router.push({ path: '/'+ getLastTimeGameName() });
                  //   }
                  // });
                }
              }

              if (data.code === 0) {
                Toast.success(_data.data.i18n.submittedSuccess);
                setTimeout(() => {
                  router.push({
                    path: 'withdrawalHistoryList',
                    query: {
                      amountType: state.amountType
                    }
                  })
                }, 1000);
              }
              state.btnDisabled = false;
            })
            .catch(function (error) {
              state.btnDisabled = false;
              console.log(error);
            });
      },
    };

    onMounted(() => {
      state.commonInfo = JSON.parse(window.localStorage.getItem("commonInfo"));
      state.eMail = isBlank(state.commonInfo) ? ' ': state.commonInfo.serviceEmail
      methods.textToast(_data.data.i18n.loading);
      state.amountType = route.query.amountType;
      axios({
        url: "/api/fund/withdraw/page",
        method: "get",
        params: {fundAccountType: state.amountType}
      })
          .then((res) => {
            state.allowCustomAmount = res.data.data.allowCustomAmount
            state.plans = res.data.data.plans;
            state.availableAmount = res.data.data.fundAccount.availableAmount;
            state.availableAmount2 = state.availableAmount <= 100 ? 0 : state.availableAmount - 100
            state.myLv = res.data.data.vip.level;
            for(let v of res.data.data.othersInfo){	
              let text = v.mobile + ' ' + _data.data.i18n.rechargeBtnText+ ': ' + v.amount
              state.othersInfoList.push(text.toString())
            }
            state.othersInfo = state.othersInfoList

            state.allowRange.min = res.data.data.allowRange.min
            state.allowRange.max = res.data.data.allowRange.max
            state.dailyCanWithdrawCount = res.data.data.dailyCanWithdrawCount
            state.withdrawSingleLimitAmount = res.data.data.withdrawSingleLimitAmount
            state.withdrawDailyLimitCount = res.data.data.vip.withdrawDailyLimitCount
            methods.getBankList();
            Toast.hide("loading");
          })
          .catch(function (error) {
            console.log(error);
            Toast.hide("loading");
          })
    });
    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>

<style>
.pop-bankLis{ padding: 20px 15px 10px 15px;width: 300px !important; border-radius: 15px;}
.pop-bankLis h6{ margin: 0;font-size: 12px; color: #f87022;}
.pop-bankLis .hd{margin-bottom: 5px; line-height: 16px; margin-bottom:10px; color:#000;}
.pop-bankLis .hd strong{ color: #f87022;}
.pop-bankLis .bd{ min-height: 132px;max-height: 132px; overflow-y: scroll; overflow-x: hidden;
  margin-bottom: 10px;padding: 10px; border-radius: 5px; background: #eee;}
.pop-bankLis .ls-ul li{ line-height: 14px; padding: 5px 0px; width: calc(50% - 4px) ; display: inline-block; 
  height: 30px; line-height: 30px;background: #fff; margin: 2px; border-radius: 5px; }
.pop-bankLis .ls-ul li span{ padding-left: 10px; padding-right: 10px;}
.pop-bankLis .ls-ul li strong{color: #12a61c;}
.pop-bankLis .ls-ul li strong.r{color: #f87022;}
.pop-bankLis .btn{ text-align: center;}
</style>
