<template>
  <div>
    <nut-navbar
    :left-show="false"
      :title="$t('setAvatar.h1')"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed"
    >
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
    </nut-navbar>


    <div class="pt50 avatar-list">
      <h4>{{$t('setAvatar.current')}}</h4>
      <ul>
        <li class="w100"><img :src="require('../assets/images/avatar/' + myImgUrl)" /></li>
      </ul>
      <h4>{{$t('setAvatar.avatars')}}</h4>
      <ul>
        <li v-for="(item, index) in list" :key="index" @click="changeAvatar(item)"><img :src="require('../assets/images/avatar/' + item)" /></li>
      </ul>
      <div class="login-btn">
        <nut-button block type="info" @click="changeAvatarSubmit">{{$t('global.confirm')}}</nut-button>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { Toast } from "@nutui/nutui";

export default {
  data() {
    return {
      myImgUrl: '',
      imgUrl: '',
      path: '',
      list:[]
    };
  },
  methods: {
    textToast(msg) {
      Toast.loading(msg, {
        id: "loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover: true,
      });
    },
    async getList() {
      this.textToast(this.$t('global.loading'));
      const res = await axios.get("/api/user/avatar/list");
      //this.list = res.data.data
      for( let i in res.data.data){
        this.list.push(res.data.data[i].slice(res.data.data[i].length-6))
      }
      Toast.hide("loading");
    },
    changeAvatar(url){
      this.path = url
      this.myImgUrl = url
    },
    changeAvatarSubmit(){
      this.textToast(this.$t('global.loading'));
      axios({
        url: "/api/user/avatar/set",
        method: "get",
        params: { path: '/images/avatar/' + this.path }
      }).then((res) => {
        if (res.data.code === 0) {
          Toast.success(this.$t('setAvatar.success'));
          setTimeout(() => {
            this.$router.push("/myHome");
          }, 1000);
        } else {
          if (res.data.msg != undefined) {
            Toast.fail(res.data.msg);
          } else {
            Toast.fail("error!");
          }
        }
      })
      .catch(function (error) {
          console.log(error);
          Toast.hide("loading");
      })

    }
  },
  created() {
    this.getList();
    this.myImgUrl = this.$route.query.img.slice(this.$route.query.img.length-6)
    this.imgUrl = localStorage.getItem('countryImgUrl')  
  },
  setup() {
    const state = reactive({
      //jsonData: [],
    });
    const router = useRouter();
    const backClick = () => {
      router.push("./myHome");
    };
    onMounted(() => {
    });
    return {
      ...toRefs(state),
      backClick,
    };
  }
};
</script>

<style scoped>
.bank-card .nut-button--square {
  position: absolute;
  right: 4px;
  bottom: 15px;
  background: transparent !important;
  color: #fff !important;
  border: 0 !important;
  opacity: 0.7;
}
.avatar-list{ margin: 10px; border: 1px solid #1a2235;padding: 10px; margin-top:60px; padding-top:0px !important;border-radius:10px; 
  background: #1c2636 url(../assets/images/global/img10.png) center top no-repeat; background-size: 150% auto;}
.avatar-list h4{ clear: both; margin: 10px; padding-top:20px; color: #fff; text-align: center;}
.avatar-list ul{ clear: both; display: flex;justify-content: center; flex-wrap: wrap;}
.avatar-list ul li{ width: 21%; height: auto; float: left; margin:5px 1%; border-radius:50%; overflow: hidden; border:3px solid #00EDA6; background: #000;}
.avatar-list ul li img{width: 100%; height: auto; margin-top: 0px; margin-bottom: -3px;}

.avatar-list ul li.w100{width: 80px; height: 80px;}
.avatar-list ul li.w100 img{width: 80px; height: 80px;}
.login-btn {
  margin: 0px 0; clear: both;
  padding: 20px 0px 10px 0px;
}


</style>
